.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.imageSuggestion {
  width: 200px !important;
  height: 200px !important;
  margin: 6px !important;
  object-fit: contain;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fc .fc-daygrid-more-link {
  position: relative;
  z-index: 4;
  cursor: pointer;
  white-space: nowrap;
  color: #fff;
  top: -23px;
  right: 20px;
  text-decoration: none;
  font-size: 10px;
}

.fc-popover-body {
  display: flex !important;
  align-items: start !important;
  min-height: 50px !important;
}

.question {
  color: #0d7b58;
  padding-bottom: 5px;
  font-weight: 600;
}

.apexcharts-legend-text {
  font-family: inherit !important;
}