:root {
    scroll-behavior: smooth;
    --color-primary: #0D7B58;
    --color-secondary: #31D68F;
    --color-grey: #707070;
    --font-primary: 'Dubai', sans-serif;
}

@font-face {
    font-family: 'Dubai';
    src: url('../public/assets/fonts/Dubai-Regular.ttf') format('truetype'),
        url('../public/assets/fonts/Dubai-Bold.ttf') format('truetype'),
        url('../public/assets/fonts/Dubai-Light.ttf') format('truetype'),
        url('../public/assets/fonts/Dubai-Medium.ttf') format('truetype');
}


body {
    background-color: #f3f4f7;
    font-family: var(--font-primary);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e2e7f1;
    border-radius: 0.25rem;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem 1.25rem;
}

.form-control {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    opacity: 1;
    margin-bottom: 5px;
    padding: 8px 15px;
    font-size: 14px;
}

.css-1s2u09g-control {
    background: #f8f8f8 0% 0% no-repeat padding-box !important;
    border: 1px solid #e8e8e8 !important;
    border-radius: 8px !important;
    opacity: 1;
    margin-bottom: 5px;
    font-size: 14px;
}

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
    box-shadow: inset 0 -1px 0 transparent;
    border: 1px solid var(--color-primary) !important;
}

.form-label {
    margin-bottom: 0.5rem;
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 500;
}

.btn-primary {
    color: #fff;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 10px 40px;
}

.btn-primary:focus {
    color: #fff;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn-primary:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.danger-button-style {
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 10px 40px;
}

.submenuStyle {
    text-decoration: none;
    color: white;
}

.d-none {
    display: none;
}

.react-photo-gallery--gallery img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    padding: 12px 10px;
}

.btn-secondary {
    color: #fff;
    background-color: gray;
    border-color: gray;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 10px 40px;
}

.pageActive .page-link {
    color: white !important;
    background-color: #0c7251 !important;
    box-shadow: none !important;
}

.page-item .page-link {
    box-shadow: none !important;
}

.module__list {
    display: contents;
}

.module__list .select__box {
    min-width: 143px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.module__list .select__box label {
    margin: 0;
    margin-right: 10px;
}

.Notfound {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cursor-pointer {
    cursor: pointer;
}

.anchorClass {
    text-decoration: none;
}

.suggested__image {
    margin-top: 20px;
}


.fc .fc-highlight {
    background: rgba(188, 232, 241, 0.3);
    background: #dbdbdb;
}

body {
    overflow-x: hidden;
}

/* jodit */

/* .jodit-react-container .jodit-container *:not(a, a *) {
    background-color: white !important;
    font-family: 'Dubai' !important;
    line-height: inherit !important;
} */

.jodit-react-container .jodit-container p,
.jodit-react-container .jodit-container h2 {
    padding: 0 !important;
}

.jodit-react-container .jodit-container ul {
    list-style-type: disc !important;
    margin: 0 !important;
}

.jodit-react-container .jodit-container li {
    margin: 0 !important;
}

.jodit-toolbar-button__button[aria-pressed=true]:not([disabled]) {
    background-color: hsla(0, 0%, 86%, .4) !important;
    outline: 0 !important;
}

.jodit-react-container .jodit-container a {
    text-decoration: none !important;
}

.jodit .jodit-popup-container,
.jodit .jodit-ui-tooltip-container,
.jodit-popup__content {
    /* display: none; */
}

div.jodit-ui-group .jodit-ui-group__blockquote,
div.jodit-ui-group .jodit-toolbar-button_pre {
    display: none;
}

.jodit-toolbar-button.jodit-ui-group__ul .jodit-toolbar-button__trigger {
    display: none;
}

.jodit-toolbar-button,
button.jodit-toolbar-button__button {
    min-height: fit-content !important;
}

.loader-div {
    display: flex;
    justify-content: center;
}

.happines_card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e2e7f1;
    background: #ffffff 0% 0% no-repeat padding-box;
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border-radius: 15px !important;
    text-align: center;
}

.happines_card h6 {
    margin: 10px 0px;
}

.happines_card h2 {
    margin: 0px;
}

.discussion_user {
    border: 1px solid #e9eaea;
    padding: 6px;
    background-color: #e9eaea;
    border-radius: 13px;
}

.fullLoaderRoute {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
}