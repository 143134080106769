.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px !important;
  border: none !important;
}
.table > thead {
  border-bottom: 2px solid transparent !important;
  border-left: 1px solid #e2e3e5 !important;
}

/* LOGIN */

.portal__login {
  padding: 15px;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.portal__login:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}
.portal__login .portal__content-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.portal__login .portal__content {
  max-width: 660px;
  width: 100%;
  background-color: #fff;
  padding: 90px 80px 70px;
  border-radius: 40px;
}
@media only screen and (max-width: 991px) {
  .portal__login .portal__content {
    padding: 90px 70px 70px;
  }
}
@media only screen and (max-width: 767px) {
  .portal__login .portal__content {
    padding: 70px 50px 50px;
  }
}
@media only screen and (max-width: 575px) {
  .portal__login .portal__content {
    padding: 50px 30px 30px;
  }
}
.portal__login .portal__header {
  background-color: transparent;
  margin-bottom: 10px;
  text-align: center;
}
.login__heading h2 {
  text-align: center;
  color: #707070;
  font-size: 18px;
  font-weight: normal !important;
  margin-bottom: 20px !important;
}
.portal__login .portal__header .portal__logo {
  display: block;
  max-width: 40vw;
}
.portal__login .portal__form {
  margin-bottom: 40px;
}
.portal__login .portal__form .portal__otp-logo {
  margin-bottom: 20px;
  display: block;
}
.portal__login .portal__form h2 {
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}
.portal__login .portal__form p {
  margin-bottom: 30px;
  font-size: 17px;
}
.portal__login .portal__form p span {
  display: block;
}
.portal__login .portal__form .portal__otp-label {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}
.portal__login .portal__form .form-group {
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
}
.portal__login .portal__form .form-group i {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.portal__login .portal__form .form-group [class^="flaticon-"]:before,
.portal__login .portal__form .form-group [class*=" flaticon-"]:before,
.portal__login .portal__form .form-group [class^="flaticon-"]:after,
.portal__login .portal__form .form-group [class*=" flaticon-"]:after {
  margin-left: 0;
  font-size: 17px;
  color: #a1a1a1;
}
.portal__login .portal__form .form-control {
  background-color: #f6f6f6;
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  padding: 10px 30px;
  color: #111111;
}
.portal__login .portal__form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.portal__login .portal__form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.portal__login .portal__form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.portal__login .portal__form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.portal__login .portal__form .portal__form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.portal__login .portal__form .portal__form-row .portal__form-col {
  padding: 10px 5px;
  text-align: center;
  margin-right: 10px;
  flex-basis: 0;
  flex-grow: 1;
}
.portal__login .portal__form .portal__form-row .portal__form-col:last-child {
  margin-right: 0;
}
.portal__login .portal__form .portal__form-btn {
  margin-bottom: 10px;
}
.portal__login .portal__form .portal__resend-wrap {
  color: #999898;
  margin-bottom: 10px;
  display: inline-flex;
  flex-wrap: wrap;
}
.portal__login .portal__form .portal__btn-resend {
  margin-left: 3px;
  box-shadow: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  color: #63bbff;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}
.portal__login .portal__form .portal__btn-resend:focus {
  outline: none;
}
.portal__login .portal__form .portal__btn-resend:hover {
  color: #000000;
}
.portal__login .portal__form .text-or {
  margin-left: 3px;
  color: #000000;
}
.portal__login .portal__btn-fill {
  margin-right: 30px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #ff5e13;
  padding: 10px 36px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portal__login .portal__btn-fill:hover {
  background-color: #f0530b;
  border-color: #f0530b;
}
.portal__login .portal__btn-fill:focus {
  outline: none;
}
.portal__login .switcher-text {
  color: #9f9f9f;
  font-size: 15px;
  margin-bottom: 7px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portal__login .switcher-text:hover {
  color: #666;
}
.portal__login .switcher-text2 {
  color: #63bbff;
  font-size: 15px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portal__login .switcher-text2.inline-text {
  margin-left: 3px;
}
.portal__login .switcher-text2:hover {
  color: #000;
}
.portal__login ul.portal__socials {
  margin-right: 15px;
  margin-bottom: 15px;
}
.portal__login ul.portal__socials li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .portal__login ul.portal__socials li {
    margin-right: 2px;
  }
}
.portal__login ul.portal__socials li:last-child {
  margin-right: 0;
}
.portal__login ul.portal__socials li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 40px;
  width: 40px;
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portal__login ul.portal__socials li.portal__facebook a {
  background-color: #3b5998;
  border-color: #3b5998;
}
.portal__login ul.portal__socials li.portal__facebook a:hover {
  background-color: transparent;
  color: #3b5998;
}
.portal__login ul.portal__socials li.portal__twitter a {
  background-color: #00acee;
  border-color: #00acee;
}
.portal__login ul.portal__socials li.portal__twitter a:hover {
  background-color: transparent;
  color: #00acee;
}
.portal__login ul.portal__socials li.portal__google a {
  background-color: #cc3333;
  border-color: #cc3333;
}
.portal__login ul.portal__socials li.portal__google a:hover {
  background-color: transparent;
  color: #cc3333;
}
.portal__login ul.portal__socials li.portal__instagram a {
  background-color: #3f729b;
  border-color: #3f729b;
}
.portal__login ul.portal__socials li.portal__instagram a:hover {
  background-color: transparent;
  color: #3f729b;
}
.portal__login ul.portal__socials li.portal__linkedin a {
  background-color: #0077b5;
  border-color: #0077b5;
}
.portal__login ul.portal__socials li.portal__linkedin a:hover {
  background-color: transparent;
  color: #0077b5;
}
.portal__login ul.portal__socials li.portal__youtube a {
  background-color: #c4302b;
  border-color: #c4302b;
}
.portal__login ul.portal__socials li.portal__youtube a:hover {
  background-color: transparent;
  color: #c4302b;
}
.portal__login .portal__footer {
  background-color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.portal__login .portal__footer p {
  color: #747474;
}

span.mandatory {
  color: #d1414f;
  margin-right: 3px;
}

.filter__arrow {
  position: relative;
  top: 6px;
  margin-left: 4px;
  color: #0d7b58;
}
i.fa.fa-sort-down.mouse.filter__arrow {
  top: 0;
}

/* DASHBOARD DESIGNS */
.search__card .form-control {
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  padding: 8px 20px !important;
}
.search__card .form-label {
  color: #101010;
  font-weight: bold;
  margin: 10px 0;
  margin-bottom: 5px;
}

.search__card {
  padding: 15px;
}

.search__card button.btn.btn-primary {
  border-radius: 8px;
  font-weight: bold;
}

.list__card .header {
  display: flex;
  align-items: end;
  padding: 0;
  height: auto;
  border: none;
  margin-bottom: 14px;
}

.list__card .icon {
  width: 67px;
  height: 45px;
  background: #e1f9ca;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  margin-left: 13px;
}
.list__card {
  position: relative;
}

.view__all {
  position: absolute;
  left: -6px;
  bottom: -16px;
}
.card-body.position-relative .view__all {
  position: absolute;
  left: 20px;
  bottom: 11px;
}
.list__card .title h4 {
  margin: 0;
  color: #101010;
  font-size: 17px;
  font-weight: bold;
  text-align: right;
}

.list__card .title {
  border-bottom: 1px solid #02ac31;
  width: 100%;
  padding-bottom: 6px;
  margin-bottom: 5px;
}

.list__card .card__count {
  display: flex;
}

.list__card .card__count .count:first-child {
  padding-left: 30px;
  margin-left: 30px;
  border-left: 3px solid #dedfdf;
}

.list__card .card__count .count span {
  font-size: 14px;
}

.list__card .card__count .count h3 {
  margin: 0;
  font-weight: 700;
  font-size: 30px;
  line-height: 1;
}

.dashboard__items .card {
  border-radius: 8px !important;
}

.board__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 12px 20px;
  margin-bottom: 20px;
  border: 0.5px solid #707070;
}

.leaderboard__sec .name {
  font-size: 14px;
}

.leaderboard__sec .name span {
  font-weight: bold;
  padding-left: 10px;
}

.leaderboard__sec .points {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.leaderboard__sec .points span {
  font-size: 12px;
  padding-left: 3px;
}


.leaderboard__sec .title h3 {
  display: inline-block;
  font-size: 13px;
  text-align: left;
  font-weight: 700;
  color: #0d7b58;
}

.leaderboard__sec .title {
  margin-top: 10px;
  text-align: right;
}

.happiness__meter .faces {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
  padding: 15px;
}

.happiness__meter .imoji__sec {
  text-align: center;
  margin-top: 40px;
  min-width: 170px;
  border-left: 3px solid #dedfdf;
}

.happiness__meter .count h6 {
  margin: 0;
}

.happiness__meter .count h2 {
  margin: 0;
  font-weight: 700;
}

.happiness__meter .count {
  margin-top: 9px;
}


.happiness__meter .faces .imoji__sec:last-child {
  border: none;
  padding: 0;
  margin-left: 0;
}


.leaderboard__sec .main__header h3 {
  font-size: 20px;
  font-weight: bold;
}

.dashboard__items .main__header .title {
  font-weight: bold;
}

.dashboard__items .main__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard__items .main__header select.form-select {
  border-radius: 8px;
  border: 0.5px solid #707070;
  font-weight: bold;
  margin-right: 10px;
  text-align: right;
  padding: 5px 20px;
}


.apexcharts-legend-marker {
  width: 20px !important;
  height: 18px !important;
  border-radius: 4px !important;
  margin-left: 16px;
}

.apexcharts-legend-series {
  display: flex;
  align-items: center;
padding: 7px 0;
  font-family: var(--font-primary);
  font-weight: bold;
}

.apexcharts-legend-text {font-size: 14px !important;}
.view__section {
  min-height: 356px;
}
.apexcharts-legend.apx-legend-position-right {
  justify-content: center !important;
}

.rmsc .options {
  padding-right: 0 !important;
}
.rmsc .item-renderer input {
  margin: 0px 0px 0px 5px !important;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border-color: #0d7b58;
  border: 1px solid;
}
.rmsc .dropdown-container {
  border: 1px solid #c9c9c9 !important;
  border-radius: 8px !important;
}
.rmsc .search input:focus {
  background: transparent;
  border: none !important;
}
.rmsc .search-clear-button {
  left: 0 !important;
  right: auto !important;
}


/* DASHBOARD DESIGNS */
.search__card .form-control {
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  padding: 8px 20px !important;
}
.search__card .form-label {
  color: #101010;
  font-weight: bold;
  margin: 10px 0;
  margin-bottom: 5px;
}

.search__card {
  padding: 15px;
}

.search__card button.btn.btn-primary {
  border-radius: 8px;
  font-weight: bold;
}

.list__card .header {
  display: flex;
  align-items: end;
  padding: 0;
  height: auto;
  border: none;
  margin-bottom: 14px;
}

.list__card .icon {
  width: 67px;
  height: 45px;
  background: #e1f9ca;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  margin-left: 13px;
}
.list__card {
  position: relative;
}

.view__all {
  position: absolute;
  left: -6px;
  bottom: -16px;
}
.card-body.position-relative .view__all {
  position: absolute;
  left: 20px;
  bottom: 11px;
}
.list__card .title h4 {
  margin: 0;
  color: #101010;
  font-size: 17px;
  font-weight: bold;
  text-align: right;
}

.list__card .title {
  border-bottom: 1px solid #02ac31;
  width: 100%;
  padding-bottom: 6px;
  margin-bottom: 5px;
}

.list__card .card__count {
  display: flex;
}

.list__card .card__count .count:first-child {
  padding-left: 30px;
  margin-left: 30px;
  border-left: 3px solid #dedfdf;
}

.list__card .card__count .count span {
  font-size: 14px;
}

.list__card .card__count .count h3 {
  margin: 0;
  font-weight: 700;
  font-size: 30px;
  line-height: 1;
}

.dashboard__items .card {
  border-radius: 8px !important;
}

.board__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 12px 20px;
  margin-bottom: 20px;
  border: 0.5px solid #707070;
}

.leaderboard__sec .name {
  font-size: 14px;
}

.leaderboard__sec .name span {
  font-weight: bold;
  padding-left: 10px;
}

.leaderboard__sec .points {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.leaderboard__sec .points span {
  font-size: 12px;
  padding-left: 3px;
}


.leaderboard__sec .title h3 {
  display: inline-block;
  font-size: 13px;
  text-align: left;
  font-weight: 700;
  color: #0d7b58;
}

.leaderboard__sec .title {
  margin-top: 10px;
  text-align: right;
}

.happiness__meter .faces {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
  padding: 15px;
}

.happiness__meter .imoji__sec {
  text-align: center;
  margin-top: 40px;
  min-width: 170px;
  border-left: 3px solid #dedfdf;
}

.happiness__meter .count h6 {
  margin: 0;
}

.happiness__meter .count h2 {
  margin: 0;
  font-weight: 700;
}

.happiness__meter .count {
  margin-top: 9px;
}


.happiness__meter .faces .imoji__sec:last-child {
  border: none;
  padding: 0;
  margin-left: 0;
}


.leaderboard__sec .main__header h3 {
  font-size: 20px;
  font-weight: bold;
}

.dashboard__items .main__header .title {
  font-weight: bold;
}

.dashboard__items .main__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard__items .main__header select.form-select {
  border-radius: 8px;
  border: 0.5px solid #707070;
  font-weight: bold;
  margin-right: 10px;
  text-align: right;
  padding: 5px 20px;
}


.apexcharts-legend-marker {
  width: 20px !important;
  height: 18px !important;
  border-radius: 4px !important;
  margin-left: 16px;
}

.apexcharts-legend-series {
  display: flex;
  align-items: center;
padding: 7px 0;
  font-family: var(--font-primary);
  font-weight: bold;
}

.apexcharts-legend-text {font-size: 14px !important;}
.view__section {
  min-height: 356px;
}
.apexcharts-legend.apx-legend-position-right {
  justify-content: center !important;
}

.rmsc .options {
  padding-right: 0 !important;
}
.rmsc .item-renderer input {
  margin: 0px 0px 0px 5px !important;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border-color: #0d7b58;
  border: 1px solid;
}
.rmsc .dropdown-container {
  border: 1px solid #c9c9c9 !important;
  border-radius: 8px !important;
}
.rmsc .search input:focus {
  background: transparent;
  border: none !important;
}
.rmsc .search-clear-button {
  left: 0 !important;
  right: auto !important;
}